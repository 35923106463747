import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Box, Grid, Group, Image, Paper, SimpleGrid, Stack, Text, useMantineTheme } from '@mantine/core';
import { IconBox, IconCurrencyDollar, IconExternalLink, IconShoppingBag } from '@tabler/icons-react';
import classes from './../../styles/StatsSegments.module.css';
import { LandingProductsStatisticsTabel, ProductsStatisticsTabel } from '../../componants/Tables';
import { useStatistics } from '../../api';
import { DonutChart, LineChart } from '@mantine/charts';
import { useTranslation } from 'react-i18next';

const cookies = new Cookies(null, { path: '/' });

interface interfaceStatistics {
    "todayIncome": number,
    "beforeDayIncome": number,
    "weekIncome": number,
    "monthIncome": number,
    "todayIncomeConfirmed": number,
    "beforeDayIncomeConfirmed": number,
    "weekIncomeConfirmed": number,
    "monthIncomeConfirmed": number,
    "todayOrders": number,
    "beforeDayOrders": number,
    "weekOrders": number,
    "monthOrders": number,
    "earnings": {
        "yearMonth": string,
        "confirmedEarnings": number,
        "pendingEarnings": number,
        "totalEarnings": number
    }[],
    "ordersYear": {
        "yearMonth": string,
        "ordersCount": number
    }[],
    "orders": {
        "total": number,
        "confirmed": number,
        "closed": number,
        "pending": number
    }
}

export function Home () {
    const { t } = useTranslation();
    const [statistics, setStatistics] = useState<interfaceStatistics>({
        "todayIncome": 0,
        "beforeDayIncome": 0,
        "weekIncome": 0,
        "monthIncome": 0,

        "todayIncomeConfirmed": 0,
        "beforeDayIncomeConfirmed": 0,
        "weekIncomeConfirmed": 0,
        "monthIncomeConfirmed": 0,

        "todayOrders": 0,
        "beforeDayOrders": 0,
        "weekOrders": 0,
        "monthOrders": 0,
        "earnings": [],
        "ordersYear": [],
        "orders": {
            "total": 0,
            "confirmed": 0,
            "closed": 0,
            "pending": 0
        }
    });
    const theme = useMantineTheme();
    const {loading, error, data: dataStatistics, refetch} = useStatistics()
    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('roleM')) {
            setRole(cookies.get('roleM'))
        }
    }, [cookies.get('roleM')])
    
    useEffect(() => {
        if (dataStatistics) {
            setStatistics(dataStatistics as any)
        }
    }, [dataStatistics])
    
    return (
        <Grid>
            <Grid.Col span={12}>
                <Box w={"100%"} h={190} bg={"#fff"} p={20}>
                    <Group justify='space-between' wrap='nowrap'>
                        <Stack justify='space-between' h={150}>
                            <Stack gap={15}>
                                <Text size='26px' fw={600} c={"dark.7"}>{t('homePage.title')} {cookies.get("nameM")} 👋</Text>
                                <Text size='sm' c={"gray.5"}>{t('homePage.description')}</Text>
                            </Stack>

                            <Text 
                                size='16px' c={"#323232"}
                                component='a'
                                target='_blank'
                                href={process.env.REACT_APP_STORE_URL}
                            >
                                <IconExternalLink size={14} style={{marginLeft: 2}} />
                                {t('homePage.link')}
                            </Text>
                        </Stack>
                        <Image src={"/header.png"} height={150} width={"auto"} visibleFrom='md' />
                    </Group>
                </Box>
            </Grid.Col>

            <Grid.Col span={12} mb={10}>
                <Text fz="xl" fw={600}>{t('homePage.sectionTitel01')}</Text>
            </Grid.Col>

            <Grid.Col span={{base: 12, md: 12}}>
                <Grid>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box h={"100%"} bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='lg' fw={'bold'} ta={'center'} mb={10}>
                                {statistics?.todayIncome || 0} د.ج / 
                                <Text span size='sm' c='gray' > {statistics?.todayIncomeConfirmed || 0} د.ج</Text>
                            </Text>
                            <Text size='md' ta={'center'}>اليوم</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='lg' fw={'bold'} ta={'center'} mb={10}>
                                {statistics?.beforeDayIncome || 0} د.ج / 
                                <Text span size='sm' c='gray' > {statistics?.beforeDayIncomeConfirmed || 0} د.ج</Text>
                            </Text>
                            <Text size='md' ta={'center'}>امس</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='lg' fw={'bold'} ta={'center'} mb={10}>
                                {statistics?.weekIncome || 0} د.ج / 
                                <Text span size='sm' c='gray' > {statistics?.weekIncomeConfirmed || 0} د.ج</Text>
                            </Text>
                            <Text size='md' ta={'center'}>اخر 7 ايام</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='lg' fw={'bold'} ta={'center'} mb={10}>
                                {statistics?.monthIncome || 0} د.ج / 
                                <Text span size='sm' c='gray' > {statistics?.monthIncomeConfirmed || 0} د.ج</Text>
                            </Text>
                            <Text size='md' ta={'center'}>هذا الشهر</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Grid.Col>

            <Grid.Col span={{base: 12, md: 12}}>
                <Box bg={"#fff"} px={20} pb={50}>
                    <Group justify="space-between" py={20} mb={20}>
                        <Text fz="lg" fw={700}>{"الارباح"}</Text>
                        <IconCurrencyDollar size="1.4rem" className={classes.icon} stroke={1.5} />
                    </Group>

                    <LineChart
                        h={300}
                        //@ts-ignore
                        data={dataStatistics?.earnings || []}
                        dataKey="yearMonth"
                        xAxisLabel='الاشهر'
                        unit=" د.ج"
                        dir='rtl'
                        series={[
                            { label: "ارباح مؤكدة", name: 'confirmedEarnings', color: 'green' },
                            { label: "ارباح غير مؤكدة", name: 'pendingEarnings', color: 'yellow' },
                            { label: "اجمالي الارباح", name: 'totalEarnings', color: 'blue' }
                        ]}
                        curveType="natural"
                        tickLine="none"
                    />
                </Box>
            </Grid.Col>


            <Grid.Col span={{base: 12, md: 12}}>
                <Box bg={"#fff"} px={20} pb={50}>
                    <Group justify="space-between" py={20} mb={20}>
                        <Text fz="lg" fw={700}>{"الطلبيات"}</Text>
                        <IconShoppingBag size="1.4rem" className={classes.icon} stroke={1.5} />
                    </Group>

                    <LineChart
                        h={300}
                        //@ts-ignore
                        data={dataStatistics?.ordersYear || []}
                        dataKey="yearMonth"
                        xAxisLabel='الاشهر'
                        dir='rtl'
                        series={[{ label: "اجمالي الطلبات", name: 'ordersCount', color: 'blue' }]}
                        curveType="natural"
                        tickLine="none"
                    />
                </Box>
            </Grid.Col>

            <Grid.Col span={{base: 12, md: 12}}>
                <Grid>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.todayOrders || 0}</Text>
                            <Text size='md' ta={'center'}>طلبات اليوم</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.beforeDayOrders || 0}</Text>
                            <Text size='md' ta={'center'}>طلبات امس</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.weekOrders || 0}</Text>
                            <Text size='md' ta={'center'}>طلبات اخر 7 ايام</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.monthOrders || 0}</Text>
                            <Text size='md' ta={'center'}>طلبات هذا الشهر</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Grid.Col>
        </Grid>
    );
}