import React, { useEffect, useState } from 'react';
import { client } from '../../lib/axiosClient';
import { ProductsTabel } from '../../componants/Tables';
import { HeadPage } from '../../componants';
import { Group, SegmentedControl, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import classes from './../../styles/Product.module.css';
import { searchSortedData, sortedData } from '../../lib/sort';
import { useDisclosure } from '@mantine/hooks';
import { UpdateProduct } from '../../componants/Modal';
import Cookies from 'universal-cookie';
import { useProducts } from '../../api';
import { ShowProductDrawer } from '../../componants/Drawer';
import { useTranslation } from 'react-i18next';
const cookies = new Cookies(null, { path: '/' });

export function Products () {
    const { t } = useTranslation();
    const [products, setProducts] = useState<any[]>([]);
    const [productsSorted, setProductsSorted] = useState<any[]>([]);
    const [value, setValue] = useState('');
    const [searsh, setSearsh] = useState('');
    const [selectedData, setSelectedData] = useState(null);
    const [openedEditModal, { open: openEditModal, close: closeEditModal }] = useDisclosure(false);
    const [openedShowModal, { open: openShowModal, close: closeShowModal }] = useDisclosure(false);
    const [role, setRole] = useState("");
    // const theme = useMantineTheme();

    useEffect(() => {
        if (cookies.get('roleM')) {
            setRole(cookies.get('roleM'))
        }
    }, [cookies.get('roleM')])
    
    const {loading, error, data: dataProducts, refetch} = useProducts()

    useEffect(() => {
        if (dataProducts.length >= 0) {
            setProducts(dataProducts)
        }
    }, [dataProducts])

    useEffect(() => {
        if (products && products?.length >= 0) {
            let filterData = products.filter((item: any) => item.posting && item.marketable)
            const newData = sortedData(filterData)
            setProductsSorted(newData)
        }
    }, [products])

    useEffect(() => {
        let filterData = products.filter((item: any) => item.posting && item.marketable)
        filterData = sortedData(filterData)
        if (searsh && searsh !== "") {
            filterData = searchSortedData(filterData, ["name", "category"], searsh)
        }
        
        setProductsSorted(filterData)
    }, [searsh])

    return (
        <>
            <HeadPage
                page={t('productsPage.name')}
                links={[
                    { title: t('productsPage.links.link01'), href: '/dashboard' },
                    { title: t('productsPage.links.link02'), href: '' }
                ]}
                labelCreate={""}
                labelExport={t('productsPage.labelExport')}
                onCreate={() => console.log()}
                onExport={() => console.log()}
                hiddenExport={true}
            />
            <Group justify='space-between' align='flex-end' mb={20}>
                <SegmentedControl
                    withItemsBorders={false} 
                    value={value}
                    onChange={setValue}
                    data={[
                        { label: t('productsPage.tags.tag01'), value: '' },
                    ]}
                    styles={{
                        root: {
                            border: "1px solid #E0E2E7",
                            background: "#fff",
                            height: 40,
                            alignItems: "center"
                        },
                        indicator: {
                            background: "#F2F7FB",
                            height: 30, minHeight: 30, maxHeight: 30,
                            boxShadow: "none"
                        }
                    }}
                    classNames={{
                        control: classes.control
                    }}
                />
                <TextInput
                    value={searsh}
                    onChange={(event) => setSearsh(event.currentTarget.value)}
                    leftSectionPointerEvents="none"
                    leftSection={<IconSearch size={18} />}
                    placeholder={t('productsPage.placeholderInput')}
                    styles={{
                        input: {height: 40}
                    }}
                />
            </Group>
            <ProductsTabel
                data={productsSorted}
                setOpenEditModal={openEditModal}
                setOpenShowModal={openShowModal}
                
                setSelectedData={setSelectedData}

                loading={loading}
            />

            <UpdateProduct title={t('productsPage.updateProduct')} refetchData={refetch} data={selectedData} opened={openedEditModal} onClose={closeEditModal} />
            <ShowProductDrawer title={t('productsPage.showProduct')} data={selectedData} opened={openedShowModal} onClose={closeShowModal} />
        </>
    );
}