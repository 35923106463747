import { useCallback, useEffect, useState } from "react";
import { client } from "../lib/axiosClient";
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });

interface _Props {
    coupon: string;
    page: string | number;
    limit: string | number;
    sort: string | number;
}

export const useOrdersCoupon = () => {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    const fetchData = useCallback(async ({coupon, limit, page, sort}: _Props) => {
        setLoading(true);
        
        try {
            const response = await client.get(`/ordersCoupon/${coupon}?page=${page || 1}&limit=${limit}&sort=${sort}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': cookies.get('tokenM') || ""
                }
            });
            
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            setData(response?.data)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, []);
    
    
    const refetch = ({coupon, limit, page, sort}: _Props) => {
        fetchData({coupon, limit, page, sort});
    };

      
    return { data, loading, error, fetchData, refetch };
}