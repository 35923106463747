import { ActionIcon, Box, Button, Checkbox, CloseButton, Divider, Grid, Group, Image, MultiSelect, NumberInput, Stack, Switch, Tabs, TagsInput, Text, TextInput, Textarea, rem } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import FormData from "form-data";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconCheck, IconCloudCheck, IconCloudUpload, IconMinus, IconPhoto, IconPlus, IconTrash, IconUpload, IconX, IconXboxX } from "@tabler/icons-react";
import classes from "../../styles/AddProduct.module.css"
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';

import { RichText } from "../Custom";
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const UpdateProduct = (props: Props) => {
    const { t } = useTranslation();
    const schema = z.object({
        price: z.number({message: t('modals.updateProduct.schemaPrice')}).gt(0, { message: t('modals.updateProduct.schemaPrice2') }),
    });
    const [loading, setLoading] = useState(false);
    const {onSubmit, reset, getInputProps, setValues, values, insertListItem, removeListItem} = useForm({
        initialValues: {
            price: 0
        },
        validate: zodResolver(schema),
        validateInputOnBlur: true,
        validateInputOnChange: true
    });
    const [product, setProduct] = useState<any>(null);

    useEffect(() => {
        if (props.opened && "data" in props && props.data !== null) {
            setProduct(props.data)
            let dataMarketer = props.data.priceMarketer.filter((item: any) => item.idMarketer == cookies.get('idM'))
            if (dataMarketer.length > 0) {
                setValues({
                    price: dataMarketer[0]?.price
                })
            }
        }
    }, [props.opened, props.data])

    const onSubmitForm = ({price}: any) => {
        setLoading(true)
        
        client.put(`/addPriceMarketerProducts/${props.data._id}`, {
            price: price,
            idMarketer: cookies.get('idM')
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('tokenM') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: t('modals.updateProduct.alert01'), color: '#fff' });
            console.log(data);
            setLoading(false)
            typeof props?.refetchData == "function" && props?.refetchData()
            closeModal()
        })
        .catch((error) => {
            notifications.show({ message: t('modals.updateProduct.alert02'), color: '#fff' });
            console.log(error)
            setLoading(false)
        });
    }


    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
    };
    
    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}} >
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                    <Grid gutter={20} justify="flex-start">
                        <Grid.Col span={{base: 12}} >
                            <NumberInput
                                label={"العمولة"}
                                placeholder={"العمولة"}
                                withAsterisk
                                {...getInputProps("price")}
                            />
                        </Grid.Col>
                        <Grid.Col span={{base: 12}} >
                            <Text size="sm" fw={"bold"} mb={5}>
                                السعر الاصلي:
                                <Text ms={5} span={true}>{product?.price} دج</Text>
                            </Text>
                            <Text size="sm" fw={"bold"} mb={5}>
                                العمولة:
                                <Text ms={5} span={true}>{values?.price} دج</Text>
                            </Text>
                            <Text size="sm" fw={"bold"}>
                                سعر البيع:
                                <Text ms={5} span={true}>{values?.price + product?.price} دج</Text>
                            </Text>
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};