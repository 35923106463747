import React, { useEffect, useState } from 'react';
import { HeadPage } from '../../componants';
import { useDisclosure } from '@mantine/hooks';
import { UpdatePasswordUser, UpdatePricesDelivery, UpdateUser } from '../../componants/Modal';
import Cookies from 'universal-cookie';
import { Box, Button, Container, Grid, Group, Image, Stack, Text, Indicator } from '@mantine/core';
import { useAccount } from '../../api';
import { useTranslation } from 'react-i18next';

const cookies = new Cookies(null, { path: '/' });

export function Settings () {
    const { t } = useTranslation();
    const [userData, setUserData] = useState<any>(null);
    const [openedEditUserModal, { open: openEditUserModal, close: closeEditUserModal }] = useDisclosure(false);
    const [openedEditPasswordUserModal, { open: openEditPasswordUserModal, close: closeEditPasswordUserModal }] = useDisclosure(false);
    const [openedEditPricesModal, { open: openEditPricesModal, close: closeEditPricesModal }] = useDisclosure(false);
    const {loading, error, data: dataAccount, refetch} = useAccount({
        id: cookies.get('idM')
    })
    const [role, setRole] = useState("");
    // const theme = useMantineTheme();

    useEffect(() => {
        if (cookies.get('roleM')) {
            setRole(cookies.get('roleM'))
        }
    }, [cookies.get('roleM')])

    useEffect(() => {
        if (dataAccount) {
            setUserData(dataAccount)
        }
    }, [dataAccount])

    return (
        <>
            <HeadPage
                page={t('settingsPage.name')}
                links={[
                    { title: t('settingsPage.links.link01'), href: '/dashboard' },
                    { title: t('settingsPage.links.link02'), href: '#' }
                ]}
                labelCreate=''
                labelExport=''
                onCreate={() => console.log()}
                onExport={() => console.log()}
                hiddenExport={true}
            />
            
            <Box bg={"#fff"} mt={20} style={{borderRadius: "5px", border: "1px solid #ced4da"}}>
                <Box p={10} py={15} style={{borderBottom: "1px solid #ced4da"}}>
                    <Text size='md'>{t('settingsPage.title01')}</Text>
                </Box>
                <Grid gutter={20} p={20} justify="flex-start" bg={"#eee"}>
                    <Grid.Col span={{base: 6, sm: 4}} >
                        <Box mih={140} bg={"#fff"} style={{borderRadius: "2px", cursor: 'pointer', boxShadow: "#eee 0px 0px 15px -5px", border: "1px solid #ced4da"}} p={20} onClick={openEditUserModal}>
                            <Stack justify='center' align='center' gap={5}>
                                <Image src={"/icons8-utilisateur-48.png"} h={35} w={35} />
                                <Text mt={15} size='md' ta={'center'}>{t('settingsPage.card01.title')}</Text>
                                <Text size='xs' c={"gray.6"} ta={'center'}>{t('settingsPage.card01.description')}</Text>
                            </Stack>
                        </Box>
                    </Grid.Col>
                    
                    <Grid.Col span={{base: 6, sm: 4}} >
                        <Box mih={140} bg={"#fff"} style={{borderRadius: "2px", cursor: 'pointer', boxShadow: "#eee 0px 0px 15px -5px", border: "1px solid #ced4da"}} p={20} onClick={openEditPasswordUserModal}>
                            <Stack justify='center' align='center' gap={5}>
                                <Image src={"/icons8-privé-2-48.png"} h={35} w={35} />
                                <Text mt={15} size='md' ta={'center'}>{t('settingsPage.card02.title')}</Text>
                                <Text size='xs' c={"gray.6"} ta={'center'}>{t('settingsPage.card02.description')}</Text>
                            </Stack>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Box>

            <Box bg={"#fff"} mt={20} style={{borderRadius: "5px", border: "1px solid #ced4da"}}>
                <Box p={10} py={15} style={{borderBottom: "1px solid #ced4da"}}>
                    <Text size='md'>{t('settingsPage.title02')}</Text>
                </Box>
                <Grid gutter={20} p={20} justify="flex-start" bg={"#eee"}>
                    <Grid.Col span={{base: 6, sm: 4}} >
                        <Box mih={140} bg={"#fff"} style={{borderRadius: "2px", cursor: 'pointer', boxShadow: "#eee 0px 0px 15px -5px", border: "1px solid #ced4da"}} p={20} onClick={openEditPricesModal}>
                            <Stack justify='center' align='center' gap={5}>
                                <Image src={"/icons8-commande-expédiée-48.png"} h={35} w={35} />
                                <Text mt={15} size='md' ta={'center'}>{t('settingsPage.card03.title')}</Text>
                                <Text size='xs' c={"gray.6"} ta={'center'}>{t('settingsPage.card03.description')}</Text>
                            </Stack>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Box>

            <UpdateUser title={t('settingsPage.updateUser')} opened={openedEditUserModal} data={userData} refetchData={refetch} onClose={closeEditUserModal} />
            <UpdatePasswordUser title={t('settingsPage.updatePasswordUser')} opened={openedEditPasswordUserModal} data={userData} onClose={closeEditPasswordUserModal} />
            <UpdatePricesDelivery title={"اسعار التوصيل"} opened={openedEditPricesModal} onClose={closeEditPricesModal} />
            
        </>
    );
}