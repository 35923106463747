import React, { useEffect, useState } from 'react';
import { client } from '../../lib/axiosClient';
import { BalanceTabel, ProductsTabel } from '../../componants/Tables';
import { HeadPage } from '../../componants';
import { searchSortedData, sortedData } from '../../lib/sort';
import { useDisclosure } from '@mantine/hooks';
import { AddInvoice, AddLandingProduct, DeleteLandingProduct, UpdateLandingProduct } from '../../componants/Modal';

import Cookies from 'universal-cookie';
import { useBalance, useInvoices } from '../../api';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Stack, Text } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';
const cookies = new Cookies(null, { path: '/' });

interface UserBalanceI {
    allConfirmedIncome: number,
    allPendingIncome: number,
    totalBills: number,
    requiredPayment: number
}

export function Balance () {
    const { t } = useTranslation();
    const [userBalance, setUserBalance] = useState<UserBalanceI>({
        allConfirmedIncome: 0,
        allPendingIncome: 0,
        totalBills: 0,
        requiredPayment: 0
    });
    const [allInvoices, setAllInvoices] = useState<any[]>([]);
    const [allInvoicesSorted, setAllInvoicesSorted] = useState<any[]>([]);

    const [value, setValue] = useState('');
    const [searsh, setSearsh] = useState('');
    const [selectedData, setSelectedData] = useState(null);
    const [openedAddModal, { open: openAddModal, close: closeAddModal }] = useDisclosure(false);
    
    const {loading: loadingBalance, error: errorBalance, data: dataBalance, refetch: refetchBalance} = useBalance()
    const {loading: loadingInvoices, error: errorInvoices, data: dataInvoices, refetch: refetchInvoices} = useInvoices()

    const [role, setRole] = useState("");
    // const theme = useMantineTheme();

    useEffect(() => {
        if (cookies.get('roleM')) {
            setRole(cookies.get('roleM'))
        }
    }, [cookies.get('roleM')])

    useEffect(() => {
        if (dataBalance) {
            setUserBalance(dataBalance as any)
        }
    }, [dataBalance])

    useEffect(() => {
        if (dataInvoices.length >= 0) {
            setAllInvoices(dataInvoices)
        }
    }, [dataInvoices])

    useEffect(() => {
        if (allInvoices && allInvoices?.length >= 0) {
            const newData = sortedData(allInvoices)
            setAllInvoicesSorted(newData)
        }
    }, [allInvoices])

    useEffect(() => {
        let filterData = allInvoices
        if (searsh && searsh !== "") {
            filterData = searchSortedData(filterData, ["name", "category"], searsh)
        }
        
        setAllInvoicesSorted(filterData)
    }, [value, searsh])

    return (
        <>
            <HeadPage
                page={"الرصيد"}
                links={[
                    { title: "لوحة التحكم", href: '/dashboard' },
                    { title: "الرصيد", href: '' }
                ]}
                labelCreate={"مطالبة بالرصيد"}
                labelExport={t('landingPage.labelExport')}
                onCreate={() => openAddModal()}
                onExport={() => console.log()}
                hiddenExport={true}
                disabledCreate={userBalance.requiredPayment == 0}
            />

            <Grid mb={20}>
                <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                    <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                        <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{userBalance.allPendingIncome} د.ج</Text>
                        <Text size='md' ta={'center'}>رصيد غير مأكد</Text>
                    </Box>
                </Grid.Col>
                <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                    <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                        <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{userBalance.allConfirmedIncome} د.ج</Text>
                        <Text size='md' ta={'center'}>رصيد مأكد</Text>
                    </Box>
                </Grid.Col>
                <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                    <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                        <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{userBalance.requiredPayment} د.ج</Text>
                        <Text size='md' ta={'center'}>مطلوب للدفع</Text>
                    </Box>
                </Grid.Col>
                <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                    <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                        <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{userBalance.totalBills} د.ج</Text>
                        <Text size='md' ta={'center'}>مدفوع</Text>
                    </Box>
                </Grid.Col>
            </Grid>

            <BalanceTabel
                data={allInvoicesSorted}
                
                setSelectedData={setSelectedData}
                loading={loadingInvoices}
            />

            <AddInvoice title={""} userBalance={userBalance} refetchData={refetchInvoices} opened={openedAddModal} onClose={() => closeAddModal()} />
        </>
    );
}